<template>

  <section class="tarjeta" id="tarjeta" @click="actionReverseCreditCard">
    <div class="w-full bg-cover p-7 rounded-2xl h-auto flex flex-col justify-between shadow-xl" :style="bgGradient">
      <div class="flex justify-between items-center min-w-[50px] mb-2" id="logo-marca">
        <img src="/images/chip-tarjeta.png" class="w-full h-full object-cover" style="max-width: 45px" alt="Chip Tarjeta">
        <img v-if="imageLink" :src="imageLink" class="w-full h-full object-cover" style="max-width: 140px" alt="Logo Banco">
      </div>
      <div class="mb-4">
        <div class="flex flex-col sm:flex-row justify-between mt-5">
          <div class="grupo">
            <p class="text-sm text-white font-bold">Número</p>
            <p class="text-base text-white text-left">{{ info.obligationNumber }}</p>
          </div>
          <div class="grupo">
            <p class="text-sm text-white font-bold text-left sm:text-right">Fecha Corte</p>
            <p class="text-base text-white text-left sm:text-right">{{ info.cutoffDate }}</p>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between mt-5">
          <div class="grupo">
            <p class="text-sm text-white font-bold">Nombre</p>
            <p class="text-base text-white text-left">{{ info.name }}</p>
          </div>

          <div class="grupo">
            <p class="text-sm text-white font-bold text-left sm:text-right">Fecha Vencimiento</p>
            <p class="text-base text-white text-left sm:text-right">{{ info.dueDate }}</p>
          </div>
        </div>
        <div class="flex justify-between mt-5">
          <div class="grupo">
            <p class="text-sm text-white font-bold">Cupo</p>
            <p class="text-base text-white text-left">{{ $h.formatCurrency(parseInt(info.disbursement)) }}</p>
          </div>
          <div class="grupo">
            <p class="text-sm text-white font-bold">Tasa EA</p>
            <p class="text-base text-white text-left">{{ info.rateEA }} %</p>
          </div>
          <div class="grupo">
            <img src="/images/logo_visa.png" class="w-full" style="max-width: 75px" alt="">
            <p class="text-sm text-center text-white font-bold">Crédito</p>
          </div>
        </div>
      </div>
    </div>

    <div class="trasera w-full h-full bg-cover p-7 rounded-2xl h-auto flex flex-col justify-between shadow-xl" :style="bgGradient">
      <div class="barra-magnetica"></div>
      <div class="datos">
        <div class="grupo" id="firma">
          <p class="label">Firma</p>
          <div class="firma"><p></p></div>
        </div>
        <div class="grupo" id="ccv">
          <p class="label">CCV</p>
          <p class="ccv"></p>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row justify-between mt-5">
        <div class="grupo">
          <p class="leyenda">Informacion de la tarjeta de crédito...</p>
        </div>
      </div>

      <a href="#" class="link-banco">{{ info.AmortizationsBank.name }}</a>
    </div>
  </section>
</template>

<script>

export default {
  name: 'infoCreditCard',
  props: {
    info: {
      type: Object
    },
    imageLink: {
      type: String,
      default: null
    },
    bgGradient: {
      type: String,
      default: null
    }
  },
  setup (props, context) {
    const actionReverseCreditCard = (e) => {
      e.target.closest('.tarjeta').classList.toggle('is-flipped')
    }

    return {
      actionReverseCreditCard
    }
  }
}
</script>

<style scoped>

/* ---------- Estilos Generales de las Tarjetas ----------*/
.tarjeta {
  width: 100%;
  position: relative;
  color: #fff;
  transition: .3s ease all;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  cursor: pointer;
  z-index: 2;
}

.tarjeta.active {
  transform: rotateY(180deg);
}

/* ---------- Tarjeta Trasera ----------*/
.trasera {
  background-size: cover;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.trasera .barra-magnetica {
  height: 60px;
  background: #000;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
}

.trasera .datos {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.trasera .datos p {
  margin-bottom: 5px;
}

.trasera .datos #firma {
  width: 70%;
}

.trasera .datos #firma .firma {
  height: 40px;
  background: white;
}

.trasera .datos #firma .firma p {
  line-height: 40px;
  font-family: 'Liu Jian Mao Cao', cursive;
  color: #000;
  font-size: 30px;
  padding: 0 10px;
  text-transform: capitalize;
}

.trasera .datos #ccv {
  width: 20%;
}

.trasera .datos #ccv .ccv {
  background: #fff;
  height: 40px;
  color: #000;
  padding: 10px;
  text-align: center;
}

.trasera .leyenda {
  font-size: 14px;
  line-height: 24px;
}

.trasera .link-banco {
  font-size: 14px;
  color: #fff;
}

.is-flipped {
  transform: rotateY(180deg);
}

</style>
